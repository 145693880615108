/* 全局样式重置 */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  body {
    font-family: 'Arial', sans-serif;
    background-color: #f4f6f8;
    color: #333;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 20px;
    min-height: 100vh;
  }
  
  /* 主体容器 */
  #root {
    width: 100%;
    max-width: 700px; /* 桌面端宽度限制 */
    padding: 20px;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    border-radius: 12px;
  }
  
  /* 顶部标题 */
  .header {
    font-size: 24px;
    font-weight: bold;
    color: #3a3b3c;
    text-align: center;
    margin-bottom: 30px;
  }
  
  /* 聊天记录容器 */
  .chat-record {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  /* 单条聊天记录容器 */
.chat-message {
    padding: 15px;
    background-color: #f9fafb;
    border-radius: 8px;
    border: 1px solid #e0e0e0;
    transition: transform 0.2s ease;
    display: flex;
    flex-direction: column;
    gap: 8px; /* 层次间隔 */
  }
  
  .chat-message:hover {
    transform: translateY(-3px);
  }
  
  /* 用户名、时间、坐标行 */
  .chat-message-header {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    color: #555;
    margin-bottom: 5px;
  }
  
  /* 聊天内容 */
  .chat-message-content {
    font-size: 16px;
    color: #333;
    line-height: 1.6;
    word-wrap: break-word;
    white-space: pre-line;
  }
  
  /* 坐标信息样式 */
  .coords {
    font-size: 14px;
    margin-top: 5px;
    font-weight: bold;
  }
  
  /* 根据 world 的类型设置颜色 */
  .coords.main {
    color: green; /* 主世界 */
  }
  
  .coords.nether {
    color: red; /* 地狱 */
  }
  
  .coords.end {
    color: goldenrod; /* 末地 */
  }
  
  /* 坐标信息 */
  .chat-coordinates {
    font-size: 12px;
    color: #999;
    margin-top: 3px;
  }
  /* 坐标信息样式 */
  .coords {
    font-size: 14px;
    margin-top: 5px;
    font-weight: bold;
  }
  
  /* 根据 world 的类型设置颜色 */
  .coords.main {
    color: green; /* 主世界 */
  }
  
  .coords.nether {
    color: red; /* 地狱 */
  }
  
  .coords.end {
    color: goldenrod; /* 末地 */
  }
  
  /* 响应式设计 */
  @media (max-width: 768px) {
    #root {
      padding: 15px;
      max-width: 100%;
    }
  
    .header {
      font-size: 20px;
    }
  
    .chat-message {
      padding: 12px;
    }
  }
  
  @media (max-width: 480px) {
    .header {
      font-size: 18px;
    }
  
    .chat-message-header {
      flex-direction: column;
      align-items: flex-start;
      gap: 4px;
    }
  
    .chat-message-content {
      font-size: 15px;
    }
  
    .chat-coordinates {
      font-size: 11px;
    }
  }
  .pagination button {
    padding: 5px 10px;
    font-size: 0.9rem;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .pagination button:disabled {
    background-color: #ced4da;
    cursor: not-allowed;
  }
  
  .pagination span {
    font-size: 1rem;
    color: #333;
  }